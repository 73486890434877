@import '@styles/mixins.style';

.acf-travel-specialist-promo__container {
  max-width: 1100px;
  margin-inline: auto;
}

.acf-travel-specialist-promo {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: var(--ra-spacing-5);

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 6fr 4fr;
  }

  &__content {
    display: flex;
    flex-direction: column;
    * {
      text-align: center;
    }
    @include breakpoint(sm) {
      * {
        text-align: left;
      }
    }

    p {
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--ra-spacing-4);
    margin-top: var(--ra-spacing-6);

    button,
    a {
      width: 100% !important;
    }

    @include breakpoint(sm) {
      flex-direction: row;
      button,
      a {
        width: fit-content !important;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: var(--ra-spacing-4);
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
}
